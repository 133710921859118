import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { API_URL } from '../config';

export default function SelectionSquadre({ setSelectedAtleta, selectedAtleta, setShowDeleted }) {

    const { t } = useTranslation()
    const [squadre, setSquadre] = useState([])
    const [atleti, setAtleti] = useState([])
    

    const callAPISquadre = async () => {
        try {
            const customerId = localStorage.getItem('customerId');
            const response = await fetch(`${API_URL}/api/squadre`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId: customerId
                }),
            })

            const data = await response.json();
            setSquadre(data.teams);
        }
        catch (err) {
            console.log(err);
        }
    };

    const callAPIAtleti = async (selectedSquadra) => {

        try {
            const customerId = localStorage.getItem('customerId');
            const response = await fetch(`${API_URL}/api/atleti`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idTeam: selectedSquadra,
                    customerId: customerId
                }),
            })

            const data = await response.json()
            setAtleti(data.atleti);
            setSelectedAtleta(-1);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        callAPISquadre();
    }, [])

    return (
        <>

            <div className="col-xs-4 col-sm-4 pad-L0">
                <label >{t('nome_squadra')}</label>
                <br />
                <select name="squadra" id="squadra" onChange={(event) => callAPIAtleti(event.target.value)}>
                    <option value="-1" key="0">{t('nessuna')}</option>
                    {
                        Array.isArray(squadre) && squadre.map((elemento, id) => {
                            return (
                                <option value={elemento.id} key={id}>{elemento.name}</option>
                            );
                        })
                    }
                </select>
                <br />

                <label>{t('nome_atleta')}</label>
                <br />
                <select name="atleti" id="atleti" value={selectedAtleta} onChange={(event) => setSelectedAtleta(event.target.value)}>
                    <option value={-1} key="0">{t('nessuno')}</option>
                    {
                        Array.isArray(atleti) && atleti.map((elemento, id) => {
                            return (
                                <option value={elemento.id} key={id}>{elemento.name} {elemento.surname}</option>
                            );
                        })
                    }
                </select>

                <br />

                <div className="row middle-sm">
                    <h4 className="px-1">{t('elementi_eliminati')}</h4>
                    <label className="switch">
                        <input type="checkbox" onChange={(e) => setShowDeleted(e.target.checked)} />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        </>
    )
}