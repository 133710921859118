import React, { useState } from "react";
import logo from './assets/img/logo-pts.png';
import lente from './assets/img/lente.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
//import jwt from 'jsonwebtoken';
import jwtDecode from "jwt-decode";
import { API_URL } from './config';

function Login() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    async function callAPILogin(event) {
        //event.preventDefault()
        const response = await fetch(`${API_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
            }),
        })

        const data = await response.json();
        if(data.user){
            const token = jwtDecode(data.user, 'secretpts');
            localStorage.setItem('token', data.user)
            localStorage.setItem('customerId', token.customerId)
            //alert('Login effettuato')
            navigate('/dashboard')
        } else {
            alert(data.message)
        }
    }

    return (
        <div className="row row-height-safari">
            <div id="pagina" className="bgRight login">
                <div className="contLogoLogin">
                    <img className="logoPTS" src={logo} alt="" />
                </div>
                <h1>{t('AREA RISERVATA')}</h1>
                <form class="loginForm">
                    <span for="first">{t('username')}</span>
                    <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" id="username" name="username" />
                    <span for="last">{t('password')}</span>
                    <div class="search-row">
                        <input value={password} onChange={(e) => setPassword(e.target.value)} type={showPassword ? "text" : "password"} id="password" name="password"
                        />
                        <button type="button"
                            onClick={() => setShowPassword(!showPassword)} 
                            id="search-button">
                                <img id="lente_img" src={lente} class="search-icon"/>
                        </button>
                    </div>
                    
                    <button type="button" onClick={callAPILogin} >{t('accedi')}</button>
                </form>
            </div>
        </div>
    )
}

export default Login
