import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { API_URL } from '../config';

export default function Performance({ idAtleta }) {
    const { t } = useTranslation();

    const [sessioni, setSessioniAtleta] = useState([]);
    const [schemi, setSchemiAtleta] = useState([]);
    const [sessioniAnnuali, setSessioniAtletaAnnuali] = useState(0);
    const [combinazioniAnnuali, setCombinazioniAtletaAnnuali] = useState(0);

    useEffect(() => {
        const callAPIinfoAtleta = async () => {
            try {
                const customerId = localStorage.getItem('customerId');
                const response = await fetch(`${API_URL}/api/performanceAtleta`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        idAtleta: idAtleta,
                        customerId: customerId
                    }),
                })

                const data = await response.json();
                setSessioniAtleta(data.sessioni);
                setSchemiAtleta(data.combinazioni);
                setSessioniAtletaAnnuali(data.sessioniAnnuali);
                setCombinazioniAtletaAnnuali(data.combinazioniAnnuali);
            }
            catch (err) {
                console.log(err);
            }
        }

        callAPIinfoAtleta();
    }, [idAtleta]);

    return (
        <div className="brdBot">
            <div className="row">
                <div className="col-xs-12 col-sm-12 pad-L0">
                    <h2>{t('performance')}</h2>

                    <div className="row">
                        <div className="col-xs-4 col-sm-4 pad-L0">
                            <div className="contMid-1">
                                <div className="circleDati">
                                    <span>{sessioni.length}</span>
                                </div>
                            </div>
                            <div className="contMid-2">
                                <h5>{t('totale')} <br />{t('nSessioni')}</h5>
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-4 pad-L0">
                            <div className="contMid-1">
                                <div className="circleDati">
                                    <span>{schemi.length}</span>
                                </div>
                            </div>
                            <div className="contMid-2">
                                <h5>{t('totale')} <br /> {t('nCombinazioni')}</h5>
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-4 pad-L0">
                            <div className="contMid-1">
                                <div className="circleDati">
                                    <span>{(schemi.length) * 32}</span>
                                </div>
                            </div>
                            <div className="contMid-2">
                                <h5>{t('totale')} <br /> {t('mPercorsi')}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-4 col-sm-4 pad-L0">
                            <div className="contMid-1">
                                <div className="circleDati">
                                    <span>{sessioniAnnuali}</span>
                                </div>
                            </div>
                            <div className="contMid-2">
                                <h5>{t('nSessioni')}<br />{t('stagione_corrente')}</h5>
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-4 pad-L0">
                            <div className="contMid-1">
                                <div className="circleDati">
                                    <span>{combinazioniAnnuali}</span>
                                </div>
                            </div>
                            <div className="contMid-2">
                                <h5>{t('nCombinazioni')} <br /> {t('stagione_corrente')}</h5>
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-4 pad-L0">
                            <div className="contMid-1">
                                <div className="circleDati">
                                    <span>{combinazioniAnnuali * 32}</span>
                                </div>
                            </div>
                            <div className="contMid-2">
                                <h5> {t('mPercorsi')} <br />{t('stagione_corrente')}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}