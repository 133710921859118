import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { API_URL } from '../config';

import statisticaImg from '../assets/img/statistic.png';
import dashboardImg from '../assets/img/dashboard.png';
import formazioneImg from '../assets/img/formazione.png';
import benvenuti from '../assets/img/benvenuti.jpg';
import close from '../assets/img/arrow-close.png';
import open from '../assets/img/arrow-open.png';
import logo from '../assets/img/logo-pts.png';
import defaultImage from '../assets/img/logo-pts copia.png';


function Sidebar() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false);
    const [customerData, setCustomerData] = useState(null);

    const langs = [
        {
            code: "it",
            name: "Italiano"
        },
        {
            code: "en",
            name: "English"
        },
    ]

    useEffect(() => {
        getCustomer();
        
    }, []);

    const getCustomer = async () => {
        try {
            const customerId = localStorage.getItem('customerId');
            const response = await fetch(`${API_URL}/api/getCustomer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId: customerId
                }),
            })
            const data = await response.json();
            setCustomerData(data);
        }
        catch (err) {
            console.log(err);
        }
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('customerId');
        navigate('/login')
    };

    return (
        <div id="menu" className="col-xs-2 col-sm-2 brd_top bgLeft" >
            {/* <div className="icoClose">
                <img src={close} alt="" id="closeIco1" />
                <img src={open} alt="" className="d-none" id="closeIco2" />
            </div> */}
            <div className="contLogo pad-T2 block">
                <img className="logoPTS" src={logo} alt="Logo website" />
                {/*<h5 className="block">{t('area_riservata')}</h5>*/}
            </div>
            <div className="contLogo">
                <img className="icoRound" src={customerData == null ? "" : customerData.image == null || customerData.image == "" ? defaultImage : customerData.image} alt="Icon User" />
                <h4 className="hidden-xs" id="menuClose1">{customerData == null ? "" : customerData.name}</h4>
                <h5 className="hidden-xs" id="menuClose1">{customerData == null ? "" : customerData.description}</h5>
            </div>
            <div className="contMenu">
                <Link to="/dashboard">
                    <li key="dashboard">
                        <a>
                            <img src={dashboardImg} alt="" />
                            <span className="hidden-xs" id="menuClose1">{t('dashboard')}</span>
                        </a>
                    </li>
                </Link>
                <Link to="/formazione">
                    <li key="formazione">
                        <a>
                            <img src={formazioneImg} alt="" />
                            <span className="hidden-xs" id="menuClose1">{t('formazione')}</span>
                        </a>
                    </li>
                </Link>
                <Link to="/statistiche">
                    <li key="statistiche">
                        <a>
                            <img src={statisticaImg} alt="" />
                            <span className="hidden-xs" id="menuClose1">{t('statistiche')}</span>
                        </a>
                    </li>
                </Link>
            </div>

            <div className="contLogout">
            <div className="center">

                {/* {langs.map(({code, name}) => (
                <button key={code} onClick={() => i18next.changeLanguage(code)} >{name}</button>
            ))} */}

                <select className="lang" onChange={(event) => i18next.changeLanguage(event.target.value)} value={i18next.language}>
                    {langs.map(({ code, name }) => (
                        <option key={code} value={code}>
                            {name}
                        </option>
                    ))}
                </select>

            </div>
            
                <button className="logout" onClick={() => logout()}>Logout</button>
            </div>

        </div>
    )
}

export default Sidebar
